import React from "react";

const HomeContent = () => {
  return (
    <div className="background">
      <h2 className="title">WORK IN PROGRESS</h2>
    </div>
  );
};

export default HomeContent;
